import React, { useState, useEffect } from "react";
import "./TraningList.css";
import { FaTimesCircle } from "react-icons/fa";
import Popup from "../Popup/Popup";
import moment from "moment";

const TraningList = ({ traningList, closeTraningList }) => {
  const [data, setData] = useState(null);
  const [passed, isPassed] = useState([]);
  const [schedule, isSchedule] = useState([]);

  let day = moment().format("DD");
  let month = moment().format("MM");
  let year = moment().format("YYYY");
  let currentDatePlusHour =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    moment().add(1, "hour").format("HH:mm");

  useEffect(() => {
    let filtered = traningList[0].filter(
      (item) => item.start > currentDatePlusHour
    );
    isPassed(filtered);

    traningList[1].map((item) => isSchedule(item));
  }, [traningList[0]]);

  console.log(passed);
  console.log(schedule);

  const setItem = (item) => {
    setData(item);
  };

  const closePopup = () => {
    setData(null);
  };

  if (traningList[0].length === 0)
    return (
      <div className="noyet">
        Nema zakazanih treninga za ovaj dan
        <FaTimesCircle className="close-icon" onClick={closeTraningList} />
      </div>
    );

  const activeClass = (item) => {
    // if (passed.includes(item)) {
    //   return "list_wrap_item_right primary";
    // } else {
    //   return "list_wrap_item_right";
    // }
    // if (passed.includes(schedule)) {
    //   return "list_wrap_item_right success";
    // } else {
    //   return "list_wrap_item_right";
    // }

    passed.map((item) => {
      if (item.id === schedule.trainings_id) {
        return "list_wrap_item_right success";
      }
    });
  };

  return (
    <div className="traning_list_wrapper">
      <FaTimesCircle className="close-icon" onClick={closeTraningList} />
      <div className="list_wrap">
        {traningList[0].length ? (
          traningList[0].map((item) => (
            <div
              key={item.id}
              onClick={() => setItem(item)}
              className="list_wrap_item"
            >
              <div className="list_wrap_item_left">
                <p className="mb">{item.title}</p>
                <p>({item.count_reserve} / 17)</p>
              </div>
              <div
                className={
                  item.id === schedule.trainings_id
                    ? "list_wrap_item_right success"
                    : passed.includes(item)
                    ? "list_wrap_item_right primary"
                    : "list_wrap_item_right"
                }
              >
                <p>
                  {item.start.substring(10, item.start.length)} -
                  {item.end.substring(10, item.start.length)}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="no-traning">Trenutno nema zakazanih treninga!</div>
        )}
      </div>
      {data !== null ? (
        <Popup data={data} schedule={traningList[1]} closePopup={closePopup} />
      ) : null}
    </div>
  );
};

export default TraningList;

import React, { Component } from "react";
import "./MyCalendar.css";
import Calendar from "react-calendar";
import moment from "moment";
import axios from "axios";
import TraningList from "../TraningList/TraningList";

class MyCalendar extends Component {
  state = {
    date: new Date(),
    lists: null
  };

  axiosCall = () => {
    let day = moment(this.state.date).format("DD");
    let month = moment(this.state.date).format("MM");
    let year = moment(this.state.date).format("YYYY");

    const trainingDate = year + "-" + month + "-" + day;

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtToken")
      },
      params: {
        trainingDate
      }
    };

    axios
      .get("https://fitness.redcellapps.info/api/trainingDay", config)
      .then(res => {
        // console.log(res);
        this.setState({ lists: res.data });
      })
      .catch(err => console.log(err.response));
  };

  onChange = date => {
    this.setState({ date });

    setTimeout(() => {
      this.axiosCall();
    }, 100);
  };

  closeTraningList = () => {
    this.setState({
      lists: null
    });
  };

  render() {
    return (
      <div className="calendar_wrapper">
        <div className="calendar_content">
          <h2>Zakaži svoj trening</h2>
          <Calendar onChange={this.onChange} value={this.state.date} />
          {this.state.lists !== null ? (
            <TraningList
              traningList={this.state.lists}
              closeTraningList={this.closeTraningList}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default MyCalendar;

import React, { useState } from "react";
import "./SignIn.css";
import axios from "axios";
import Logo from "../../images/logo.png";
import { FaEnvelope, FaLock } from "react-icons/fa";

const SignIn = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);

  const onSubmit = e => {
    e.preventDefault();

    const data = {
      email,
      password
    };

    axios
      .post("https://fitness.redcellapps.info/api/login", data)
      .then(res => {
        localStorage.setItem("jwtToken", res.data);
        window.location.href = "/";
      })
      .catch(() => setError("Email ili lozinka nije tačna, ili je nalog istekao."));
  };

  return (
    <div className="signin">
      <div className="signin_content">
        <div className="signin_left">
          <img src={Logo} alt="LogoPicture" />
        </div>
        <form className="signin_right" onSubmit={onSubmit}>
          <h2>Prijavi se</h2>
          <div className="signin_input_row">
            <FaEnvelope />
            <input
              type="email"
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="signin_input_row">
            <FaLock />
            <input
              type="password"
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          {error ? <p className="error">{error}</p> : null}
          <button>Prijavi se</button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;

import React, { useState, useEffect } from "react";
import "./Popup.css";
import axios from "axios";
import moment from "moment";

const Popup = ({ data, closePopup, schedule }) => {
  const [msg, setMsg] = useState("");
  const [has, isHas] = useState(false);
  const [today, setToday] = useState(false);

  useEffect(() => {
    schedule.filter((item) =>
      item.trainings_id === data.id ? isHas(true) : isHas(false)
    );

    schedule.filter((item) =>
      item.training_date === data.start.substring(5, 10)
        ? setToday(true)
        : setToday(false)
    );
  });

  let day = moment().format("DD");
  let month = moment().format("MM");
  let year = moment().format("YYYY");
  let currentDatePlusHour =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    moment().add(1, "hour").format("HH:mm");

  const scheduleTrening = () => {
    const trainingId = {
      trainingId: data.id,
    };
    axios
      .post(
        "https://fitness.redcellapps.info/api/trainingReserve",
        trainingId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        }
      )
      .then((res) => {
        isHas(true);
        setMsg(res.data);
        window.location.href = "/";
      })
      .catch((err) => console.log(err.response));
  };

  const cancelTraning = () => {
    const trainingId = {
      trainingId: data.id,
    };
    axios
      .post(
        "https://fitness.redcellapps.info/api/trainingReserveDelete",
        trainingId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        }
      )
      .then((res) => {
        isHas(false);
        setMsg(res.data);
        window.location.href = "/";
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <div className="popup_wrapper" onClick={closePopup}>
      <div className="popup_holder" onClick={(e) => e.stopPropagation()}>
        <h2>{data.title}</h2>
        <p>{data.start}</p>
        <p>{data.count_reserve} / 15</p>
        <div className="summary">
          <p>{data.summary}</p>
          <p className="instructor">
            <b>Instruktor: </b>
            {data.instructor}
          </p>
        </div>
        {msg}
        <div className="buttons">
          {currentDatePlusHour < data.start && !has && !today ? (
            <button onClick={scheduleTrening} className="primary">
              Zakaži
            </button>
          ) : null}
          {has && today ? (
            <button onClick={cancelTraning} className="warning">
              Otkaži
            </button>
          ) : null}
          <button onClick={closePopup} className="primary">
            Nazad
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;

import React from "react";
import "./Header.css";
import { FaPowerOff } from "react-icons/fa";
import Logo from "../../images/logo.png";

const logout = () => {
  localStorage.removeItem("jwtToken");
  window.location.href = "/";
};

const Header = () => {
  return (
    <div className="navbar">
      <img src={Logo} alt="LogoPicture" />
      <span onClick={logout}>
        <FaPowerOff />
      </span>
    </div>
  );
};

export default Header;
